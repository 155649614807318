import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import Layout from "../components/Layout";
import "../style/highly-regulated.scss";

const translations = {
  atlas: {
    en: "More about Atlas",
    es: "More about Atlas",
    it: "More about Atlas",
  },
};

const HighlyRegulatedPage = ({ data, pageContext, path }) => {
  const { frontmatter } = data.markdownRemark;
  const [drinkingAge, setDrinkingAge] = useState(null);

  const content = frontmatter[pageContext.language || "en"];
  const homeUrl =
    pageContext.language === "en" ? "/" : `/${pageContext.language}/`;

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: "50%", left: 0, behavior: "smooth" });
  }, [drinkingAge]);

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <div className="tech-databases">
        <div id="highly-regulated-page">
          <div
            className={"blocking-modal" + (!drinkingAge ? "" : " hide")}
            style={{ alignItems: "start",flexDirection: "row", marginTop: "24px" }}
          >
            <div>
              <div>
                <h2>
                  {content.modal.title1}
                  <br />
                  {content.modal.title2}
                </h2>
                {drinkingAge === null && (
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <button
                      className="mach9"
                      onClick={() => setDrinkingAge(true)}
                    >
                      <div>{content.modal.buttonyes}</div>
                    </button>
                    <button
                      className="mach9"
                      onClick={() => setDrinkingAge(false)}
                    >
                      <div>{content.modal.buttonno}</div>
                    </button>
                  </div>
                )}
                <div style={{ marginTop: "1rem" }}>
                  {content.modal.description}
                </div>
                <div className="left neon"></div>
                <div className="top neon"></div>
                <div className="right neon"></div>
                <div className="bottom neon"></div>
              </div>
            </div>
          </div>

          <section
            id="highly-regulated-hero"
            style={{ display: !drinkingAge ? "none" : "" }}
          >
            <div
              id="highly-regulated-expertise"
              style={{
                width: "90rem",
                maxWidth: "100%",
                marginInline: "auto",
                marginTop: "6rem",
              }}
            >
              <div>
                {/* <div className="catchy">{content.herosection.title1}</div> */}
                <h2>{content.expertisesection.title}</h2>
                <p>{content.expertisesection.description}</p>
              </div>
              <div className="dark-cards">
                {content.expertisesection.cards.map((card) => (
                  <div className="dark-card">
                    <img
                      src="/static/assets/services/services/service-bg.png"
                      className="bg"
                    />
                    <div className="content">
                      <h3>{card.title}</h3>
                      <p>{card.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div style={{ marginTop: "4rem", textAlign: "right" }}>
            <Link to={homeUrl + "atlas/"}>
              <button className="mach9">
                <div>
                  {translations.atlas[pageContext.language]}
                  <ArrowIcon />
                </div>
              </button>
            </Link>
          </div> */}
            </div>

            <div>
              <div className="catchy">{content.herosection.title1}</div>
              <h2>{content.herosection.title2}</h2>
            </div>
            <div>
              <div id="regulated-cards">
                {content.herosection.cards?.map((card, i) => (
                  <div
                    className="card"
                    key={`card-${i}`}
                    id={`card-${i}`}
                    // style={{ backgroundColor: "#61859f" }}
                  >
                    <div
                      className="bg"
                      style={{
                        backgroundImage: `URL(${card.cardimage.publicURL})`,
                      }}
                    />
                    <div className="content">
                      <h3>{card.title}</h3>
                      <p>{card.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* className={"tech-databases" + (!drinkingAge ? " blocked" : "")} */}
    </Layout>
  );
};

HighlyRegulatedPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default HighlyRegulatedPage;

export const pageQuery = graphql`
  query HighlyRegulatedPageTemplate {
    markdownRemark(
      frontmatter: { en: { templateKey: { eq: "highly-regulated-page" } } }
    ) {
      frontmatter {
        en {
            modal {
              title1
              title2
              buttonyes
              buttonno
              description
            }
          expertisesection {
            title
            description
            cards {
              title
              description
            }
          }
          herosection {
            title1
            title2
            cards {
              title
              description
              cardimage {
                publicURL
              }
            }
          }
        }
        es {
          modal {
              title1
              title2
              buttonyes
              buttonno
              description
          }
          expertisesection {
            title
            description
            cards {
              title
              description
            }
          }
          herosection {
            title1
            title2
            cards {
              title
              description
              cardimage {
                publicURL
              }
            }
          }
        }
        it {
          modal {
              title1
              title2
              buttonyes
              buttonno
              description
          }
          expertisesection {
            title
            description
            cards {
              title
              description
            }
          }
          herosection {
            title1
            title2
            cards {
              title
              description
              cardimage {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
